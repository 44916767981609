.navbar-link{
    color: #5e72e4 !important;
}

.align-component-enter{
    text-align: center;
}

.bg-gradient-lightblue {
    background: linear-gradient(35deg, #4575c7 0, #4A75c7 100%) !important;
  }

.text-center{
    text-align: center;
}

.align-component-justify{
    text-align: justify;
}

.icon-toggle{
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar-margin {
    padding-right: 0.8rem !important;
    padding-left: 0.8rem !important;
}